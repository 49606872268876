import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import { Icons } from '../../components/atoms/Icon'
import {
  NewsletterPushProps,
  NewsletterPushValues,
} from '../../components/molecules/NewsletterPush'
import { Device, Member } from '../../graphql/generated/api-graphql'
import {
  memberUpdateService,
  newsletterSubscribeService,
} from '../../redux/member/services'
import Router, { routes } from '../../routes/Router'
import { sizes } from '../../theme'
import tracking from '../../tracking'

export const NewsletterPushDefaultProps = (
  t: any,
  newsletterService?: typeof newsletterSubscribeService.values,
  memberService?: typeof memberUpdateService.values
): NewsletterPushProps => ({
  imageProps: {
    maxWidth: 408,
    withHD: true,
    alt: ' ',
    width: 408,
    height: 266,
    images: [
      {
        src: '/static/assets/images/tests/register-push.jpg',
        size: 408,
      },
    ],
  },
  submitProps: {
    label: t('subscribe_newsletter_title'),
    variant: ActionButtonVariant.secondary,
    iconProps: {
      icon: Icons.arrowRight,
    },
    iconPosition: 'right',
    ...((newsletterService?.pending || memberService?.pending) && {
      isPending: newsletterService?.pending,
    }),
  },
  fieldCheckProps: {
    name: 'consent1',
    label: t('register_block_consent', {
      link: Router.getRouteUrl(routes.home),
    }),
  },
  validation: {
    required: t('please_fill_your_email'),
    consent: t('register_block_consent_mandatory'),
  },
  onSubmit: (values) => console.log('submit', values),
  title: t('cheeseletterPush_title'),
  legendProps: {
    text: t('register_block_consent_details_all', {
      link: Router.getRouteUrl(routes.home),
      linkForm: '/st/contact?mailObject=7', // @TODO: dynam
      linkRights: '/nf/privacy', // @TODO: dynam
    }),
  },
  messagesProps: {
    messages: newsletterService?.errors || memberService?.errors,
  },
  ...((newsletterService?.success || memberService?.success) && {
    successProps: {
      title: t('cheeseletterPush_confirmation_title'),
      text: t('cheeseletterPush_confirmation_text'),
    },
  }),
})

export const newsletterSubmit = (
  values: NewsletterPushValues,
  user: Member,
  newsletterSubscribeHandler: (values: any) => void,
  memberUpdateHandler: (values: any) => void,
  trackingPage?: string
) => {
  tracking.newsletter(trackingPage ?? '')
  const windowWidth = window ? window.innerWidth : sizes.desktop
  const device: Device =
    windowWidth < sizes.tablet
      ? Device.Mobile
      : windowWidth < sizes.desktop
      ? Device.Tablet
      : Device.Desktop

  if (values.consent1) {
    newsletterSubscribeHandler({
      email: user?.email || '',
      device: device,
    })
  }
  memberUpdateHandler({
    input: {
      cheeseLetter: values.consent1,
      partnerLetter: values.consent2,
    },
  })
}
