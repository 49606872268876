import { Icons } from '../../components/atoms/Icon'
import { RegisterPushProps } from '../../components/molecules/RegisterPush'
import { newsletterSubscribeService } from '../../redux/member/services'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'

// footer register push
export const RegisterPushDefaultProps = (
  t: any,
  service?: typeof newsletterSubscribeService.values
): RegisterPushProps => ({
  imageProps: {
    maxWidth: 408,
    withHD: true,
    alt: t('join_QVDF_community') ?? '',
    width: 408,
    height: 266,
    images: [
      {
        src: '/static/assets/images/tests/register-push.jpg',
        size: 408,
      },
    ],
  },
  fieldProps: {
    placeholder: t('your_email'),
    submitProps: {
      label: t('register'),
      iconProps: undefined,
      ariaLabel: t('register_i'),
      ...(service?.pending && {
        isPending: service?.pending,
        iconProps: {
          icon: Icons.refresh,
        },
      }),
    },
  },
  fieldCheckProps: {
    name: 'consent1',
    label: t('register_block_consent', {
      link: Router.getRouteUrl(routes.home),
    }),
  },
  validation: {
    email: t('invalid_email'),
    required: t('please_fill_your_email'),
    consent: t('register_block_consent_mandatory'),
  },
  onSubmit: (v) => {
    Router.pushRoute(routes.signup, { email: v.email })
  },
  title: t('join_QVDF_community'),
  legendProps: {
    text: t('register_block_consent_details_all', {
      link: Router.getRouteUrl(routes.home),
      linkForm: '/st/contact?mailObject=7', // @TODO: dynam
      linkRights: '/nf/privacy', // @TODO: dynam
    }),
  },
  messagesProps: {
    messages: service?.errors,
  },
  ...(service?.success && {
    successProps: {
      title: t('welcome_to_the_community'),
      text: t('you_should_receive_registration_email'),
    },
  }),
})
