import { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, themes } from 'src/theme'
import React, { FC, useEffect } from 'react'
import { Provider } from 'react-redux'
import { useRouter } from 'next/router'
import NextProgressBar from 'nextjs-progressbar'

import '../helpers/BugsnagHelpers'
import reduxStore from '../redux/store'
import I18nProvider from '../i18n/Provider'
import Metas from '../components/atoms/Metas'
import ReduxInit from '../relay/ReduxInit'
import { PageProps } from '../relay/Common/PageProps'
import { colors } from '../theme'
import ToasterWrapper from '../relay/Toaster/wrapper'
import Redirector from '../components/atoms/Redirector'
import tracking from '../tracking'
import APP from '../configuration/app'
import { app } from '../configuration'
import ServiceWorkerUnregister from '../utils/ServiceWorkerUnregister'
import PreviousRoute from '../components/atoms/PreviousRoute/PreviousRoute'
import PushAuthPopinWrapper from '../components/molecules/PushAuthPopin/wrapper'
// import PushReductionsPopinWrapper from '../components/molecules/PushReductionsPopin/wrapper'

import MaintenancePage from './_maintenance'
import ErrorPage from './_error'

const { store } = reduxStore

const Application: FC<AppProps<PageProps>> = ({ Component, ...rest }) => {
  const JSCComponent = Component as FC<PageProps>
  const pageProps: PageProps = rest?.pageProps
  const router = useRouter()

  // unregister Service workers
  useEffect(() => {
    if (!app.USE_PWA) {
      ServiceWorkerUnregister()
    }
  }, [])

  useEffect(() => {
    router.prefetch = async () => {}
  }, [router])

  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
      tracking.pageview(APP.APP_URL + url)
    })
  }, [router.events])

  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
        <ReduxInit />
        <I18nProvider store={pageProps?.i18n}>
          <ThemeProvider theme={themes['theme']}>
            <Metas {...pageProps?.metas} />
            <NextProgressBar
              color={colors.arapawa}
              height={2}
              options={{ showSpinner: false }}
            />
            {pageProps?.configuration?.maintenance?.enabled ||
            pageProps?.highCoMaintenanceEnabled ? (
              <MaintenancePage {...pageProps} />
            ) : (rest as any)?.error || pageProps?.error ? (
              <>
                <ErrorPage error={(rest as any)?.error} {...pageProps} />
                <span style={{ display: 'hidden' }}>O2rUl</span>
              </>
            ) : (
              <>
                <JSCComponent {...pageProps} />
                <span style={{ display: 'hidden' }}>O2rUl</span>
              </>
            )}
            <ToasterWrapper />
            <Redirector />
            <PushAuthPopinWrapper />
            {/* <PushReductionsPopinWrapper /> */}
            <PreviousRoute />
          </ThemeProvider>
        </I18nProvider>
      </Provider>
    </>
  )
}

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   // console.info(metric) // The metric object ({ id, name, startTime, value, label }) is logged to the console
//   /*ga('send', 'event', {
//       eventCategory:
//         metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//       eventAction: name,
//       eventValue: Math.round(
//         metric.name === 'CLS' ? metric.value * 1000 : metric.value
//       ), // values must be integers
//       eventLabel: metric.id, // id unique to current page load
//       nonInteraction: true, // avoids affecting bounce rate.
//     })*/
// }

export default Application
