import React, { FC } from 'react'
import cx from 'classnames'

import { FormikFormProps } from '../../../form/FormikForm'
import { FormikFieldProps } from '../../../form/FormikField'
import { FormMessagesProps } from '../../../form/FormMessages'
import { FormSubmitButtonProps } from '../../../form/FormSubmitButton'
import BaseModal, { BaseModalProps } from '../BaseModal'

import * as SC from './styled'

export type LightAuthOptinModalProps = BaseModalProps & {
  className?: string
  background?: string
  title: string
  text?: string
  legalNotice?: string
  formikForm: FormikFormProps
  submitButton: FormSubmitButtonProps
  fields: {
    cheeseLetter: FormikFieldProps
    partnerLetter?: FormikFieldProps
  }
  errors?: FormMessagesProps
}

const LightAuthOptinModal: FC<LightAuthOptinModalProps> = (props) => {
  const {
    className,
    background,
    title,
    errors,
    fields,
    formikForm,
    submitButton,
    text,
    legalNotice,
    ...modalProps
  } = props

  return (
    <BaseModal
      {...modalProps}
      className={cx('SoloCapriceValentineGameOptinModal', className)}
    >
      <SC.Content $background={background}>
        <SC.Title>{title}</SC.Title>
        {text && <SC.Text>{text}</SC.Text>}
        <SC.Form {...formikForm}>
          <SC.AuthFields>
            <SC.RulesField {...fields.cheeseLetter} />
            {fields.partnerLetter && (
              <SC.RulesField {...fields.partnerLetter} />
            )}
          </SC.AuthFields>
          {legalNotice && <SC.LegalNotice>{legalNotice}</SC.LegalNotice>}
          {errors && <SC.Errors {...errors} />}
          <SC.SubmitButton {...submitButton} />
        </SC.Form>
      </SC.Content>
    </BaseModal>
  )
}

export default LightAuthOptinModal
