import { RegisterBlockProps } from '../../components/molecules/RegisterBlock'
import { Icons } from '../../components/atoms/Icon'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import { RegisterBlockPosition } from '../../components/molecules/RegisterBlock/styled'
import { ListIconHtmlVariant } from '../../components/atoms/ListIconHtml/types'

export const RegisterBlockDefaultProps = (t: any): RegisterBlockProps => ({
  title: t('join_QVDF_community'),
  infos: {
    items: [
      {
        iconProps: {
          icon: Icons.coupons2,
          width: 28,
          height: 28,
        },
        htmlProps: {
          text: t('register_block_item_1'),
        },
        ctaProps: {
          text: t('register_block_item_1_cta_label'),
          href: '/bons-de-reduction',
        },
      },
      {
        iconProps: {
          icon: Icons.toque,
          width: 28,
          height: 28,
        },
        htmlProps: {
          text: t('register_block_item_2'),
        },
      },
      {
        iconProps: {
          icon: Icons.heartLine,
          width: 28,
          height: 28,
        },
        htmlProps: {
          text: t('register_block_item_3'),
        },
      },
    ],
    variant: ListIconHtmlVariant.Register,
  },
  formTitle: t('register_block_subtitle'),
  fieldProps: {
    placeholder: t('register_block_placeholder'),
    submitProps: {
      ariaLabel: t('register_block_ariaLabel'),
    },
  },
  fieldCheckProps: {
    name: 'legal',
    label: t('register_block_consent', {
      link: Router.getRouteUrl(routes.home),
    }),
  },
  legalText: {
    text: t('register_block_consent_details', {
      linkForm: '/st/contact?mailObject=7', // @TODO: dynam
      linkRights: '/nf/privacy', // @TODO: dynam
    }),
  },
  onSubmit: (v) => {
    Router.pushRoute(routes.signup, { email: v.email })
  },
  validation: {
    email: t('invalid_email'),
    required: t('please_fill_your_email'),
    consent: t('register_block_consent_mandatory'),
  },
  imageProps: {
    withHD: true,
    maxWidth: 1200,
    width: 1200,
    height: 92,
    alt: t('join_QVDF_community') ?? '',
    images: [
      {
        src: '/static/assets/images/common/register-bg.jpg',
        size: 1200,
        type: 'images/jpeg',
      },
      {
        src: '/static/assets/images/common/register-bg.webp',
        size: 1200,
        type: 'images/webp',
      },
      {
        src: '/static/assets/images/common/register-bg-min.jpg',
        size: 600,
        type: 'images/jpeg',
      },
      {
        src: '/static/assets/images/common/register-bg-min.webp',
        size: 600,
        type: 'images/webp',
      },
    ],
  },
  mobileImageProps: {
    withHD: true,
    maxWidth: 370,
    width: 370,
    height: 200,
    alt: t('join_QVDF_community') ?? '',
    images: [
      {
        src: '/static/assets/images/common/register-bg-min.jpg',
        size: 600,
        type: 'images/jpeg',
      },
      {
        src: '/static/assets/images/common/register-bg-min.webp',
        size: 600,
        type: 'images/webp',
      },
    ],
  },
  position: RegisterBlockPosition.row,
})
