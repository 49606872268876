import React, { FC } from 'react'
import * as Yup from 'yup'

import { PictureProps } from '../../atoms/Picture'
import { HtmlProps } from '../../atoms/Html'
import FormikField from '../../form/FormikField'
import FormFieldInputWithSubmit, {
  FormFieldInputWithSubmitProps,
} from '../../form/fields/FormFieldInputWithSubmit'
import { Icons } from '../../atoms/Icon'
import FormMessages, { FormMessagesProps } from '../../form/FormMessages'
import FormFieldCheckbox, {
  FormFieldCheckboxProps,
} from '../../form/fields/FormFieldCheckbox'

import * as SC from './styled'

export type RegisterPushValues = {
  email: string
}

export type RegisterPushProps = {
  className?: string
  imageProps: PictureProps
  title?: string
  legendProps?: HtmlProps
  fieldProps?: FormFieldInputWithSubmitProps
  fieldCheckProps?: FormFieldCheckboxProps
  fieldCheck2Props?: FormFieldCheckboxProps
  validation: {
    email?: string
    required?: string
    consent?: string
  }
  onSubmit: (v: RegisterPushValues) => void
  successProps?: {
    title?: string
    text?: string
  }
  messagesProps?: FormMessagesProps
}

const RegisterPush: FC<RegisterPushProps> = ({
  className,
  imageProps,
  title,
  legendProps,
  fieldProps,
  fieldCheckProps,
  fieldCheck2Props,
  onSubmit,
  validation,
  successProps,
  messagesProps,
}) => {
  return (
    <SC.Main className={className}>
      <SC.BackgroundImage {...imageProps} aria-hidden />
      <SC.Overlay aria-hidden />
      <SC.Content>
        {successProps ? (
          <>
            <SC.Title>{successProps?.title}</SC.Title>
            <SC.CheckMark icon={Icons.check} />
            <SC.Text>{successProps?.text}</SC.Text>
          </>
        ) : (
          <>
            <SC.ContentTop>
              <SC.Title>{title}</SC.Title>
              <SC.Form
                initialValues={{ email: '', consent1: false, consent2: false }}
                scrollOnError={false}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(validation?.email)
                    .required(validation?.required),
                  consent1: Yup.boolean(),
                  consent2: Yup.boolean(),
                })}
                validateOnChange
                onSubmit={onSubmit}
              >
                <FormikField
                  name="email"
                  Component={FormFieldInputWithSubmit}
                  {...fieldProps}
                />
                <SC.LegalField
                  name="consent1"
                  Component={FormFieldCheckbox}
                  {...fieldCheckProps}
                />
                {fieldCheck2Props && (
                  <SC.LegalField
                    Component={FormFieldCheckbox}
                    {...fieldCheck2Props}
                  />
                )}
              </SC.Form>
              {messagesProps && <FormMessages {...messagesProps} />}
            </SC.ContentTop>
            {legendProps && <SC.Legend {...legendProps} />}
          </>
        )}
      </SC.Content>
    </SC.Main>
  )
}

export default RegisterPush
